<template>
  <CRow class="form-group kunden-auswahl-formgroup">
    <CCol sm="12">
      <label :class="formLabelClass">{{formLabel}}</label>
      <ValidationProvider :rules="rules" immediate v-slot="{ errors }">
        <v-select
          v-model="filterValue"
          ref="kundeselect"
          :options="paginated"
          placeholder="Bitte wählen Sie einen Kunden aus ..."
          :filterable="false"
          v-on:input="inputEvent"
          :class="isValidClass(errors)"
          :get-option-label="(option) => generateLabelName(option)"
          @search="onSearch"
        >
          <template #no-options>
            Keine Kunden gefunden.
          </template>
        </v-select>
      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import Fuse from 'fuse.js'

export default {
  name: 'KundenAuswahl',
  props: {
    value: [Object, Event],
    formLabel: {
      default: 'Kunden',
      type: String
    },
    formLabelClass: {
      default: 'd-none',
      type: String
    },
    rules: {
      default: 'required',
      type: String
    },
    deleteButton: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      index: null,
      isValid: false,
      filterValue: null,
      kundenDB: null,
      result: [],
      offset: 0,
      limit: 10,
      wait: false,
      countwait: 0,
      optionsKeys:
        {
          keys:
            [
              { name: 'kdnr', weight: 10 },
              { name: 'name1', weight: 10 },
              { name: 'plz', weight: 0.5 },
              { name: 'ort', weight: 0.5 },
              { name: 'strasse', weight: 10 }
            ]
        },
      ignoreLocation: false,
      shouldSort: true,
      location: 0,
      fieldNormWeight: 0,
      threshold: 0.2,
      distance: 4,
      minMatchCharLength: 3
    }
  },
  computed: {
    kunden () {
      return this.result
    },
    filtered () {
      return this.result.filter((kunde) =>
        kunde.toLocaleLowerCase().includes(this.search.toLocaleLowerCase())
      )
    },
    paginated () {
      return Array.isArray(this.result) ? this.result.slice(0, 50) : []
    }
  },
  async mounted () {
    this.loadKunden().then((response) => {
      this.valueToLocal()
      const optionsKeys = this.optionsKeys
      this.index = Fuse.createIndex(optionsKeys.keys, this.kundenDB)
    })
  },
  watch: {
    value: function () {
      this.valueToLocal()
    }
  },
  methods: {
    valueToLocal: function () {
      this.filterValue = this.value
      if (this.value) {
        this.isValid = true
      }
      // Value nach Kunde
      const self = this
      if (this.filterValue && this.kunden) {
        const arrayid = Vue._.findIndex(this.kunden, function (k) { return k.kundenid === self.filterValue })
        if (arrayid !== -1) {
          this.filterValue = this.kunden[arrayid]
        }
      }
    },
    onSearch (query) {
      this.search = query
      this.offset = 0
      const result = this.fuseSearch(this.kundenDB, query)
      this.result = result
    },
    inputEvent: function (newValue) {
      if (newValue) {
        this.isValid = true
        this.$emit('input', newValue)
      } else {
        // Null, bzw. delete-Button
        this.$emit('input', null)
      }
    },
    isValidClass: function (errors) {
      let result = 'form-control'
      if (errors.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    },
    fuseSearch (options, search) {
      const fuse = new Fuse(options, {
        keys: this.optionsKeys.keys,
        isCaseSensitive: false,
        minMatchCharLength: this.minMatchCharLength,
        threshold: this.threshold,
        location: this.location,
        shouldSort: true
      }, this.index)

      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
    generateLabelName: function (kunde) {
      return kunde.name1 + '  |   ' + kunde.strasse + ' ' + kunde.hausnr + ' , ' + kunde.ort
    },
    generateStreet: function (kunde) {
      return kunde.name1 + '  |   ' + kunde.strasse + ' ' + kunde.hausnr + ' , ' + kunde.ort
    },
    loadKunden: function () {
      return Vue.axios.get('/kunden/get')
        .then((response) => {
          this.kundenDB = response.data.filter(kunde => {
            return kunde.aktiv
          })
          this.result = response.data
        })
    }
  }
}
</script>

<style lang="scss">
div.kunden-auswahl-formgroup {
  div.form-control {
    padding: 1px 0 0 1px;
    div.vs__dropdown-toggle {
      border: none;
    }
  }
  div.v-select {
    height: auto;
  }
}

.vs__dropdown-menu{
  max-height: 90vh;
}
</style>
